<template>
  <div class="app-container">
    <h2>{{ $t('requestForm.formTitle') }}</h2>
    <el-link v-if="requestFormURL" :href="requestFormURL" target="_blank">
      {{ $t('requestForm.openRequestForm') }}
    </el-link>
  </div>
</template>

<script>
export default {
  name: 'RequestForm',
  computed: {
    requestFormURL() {
      return this.$store.getters['configuration/value']('requestForm.URL');
    }
  }
};
</script>

<style scoped></style>
